import {
  Button,
  Empty,
  Layout,
  List,
  LoadingIndicator,
  Menu,
  Sidebar,
  TitlePortal,
  UserMenu,
  useCreate,
  useGetIdentity,
  useGetOne,
  useNotify,
  usePermissions,
  useStore,
} from "react-admin";
import { ReactQueryDevtools } from "react-query/devtools";
import { AppBar,useDataProvider } from "react-admin";
import Box from "@mui/material/Box";

import BookIcon from "@mui/icons-material/Book";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import PeopleIcon from "@mui/icons-material/People";
import LabelIcon from "@mui/icons-material/Label";
import { BsBack, BsFillGearFill } from "react-icons/bs";
import EmailSettingsLogo from "../assets/images/mailLogo.png";
import logReport from "../assets/images/logReport.png";
import dashboardLogo from "../assets/images/dashboard.png";
import Leadmgt from "../assets/images/Frame (2).png";
import Revenuemgt from "../assets/images/Frame.png";
import Docuploadmgt from "../assets/images/Frame (1).png";
import AgentMgt from "../assets/images/Frame (3).png";
import TermnsAndConditions from "../assets/images/termsConditions.png";
import PredictScreenLogo from "../assets/images/Logo- Horizontal.png";

import userSetupLogo from "../assets/images/userSetupLogo.png";
import Loglogo from "../assets/images/Vector (1).png";
// import AdverseLogo from "../assets/images/Frame (6).png";
import ManageUserLogo from "../assets/images/ManageUser.png";
import GeographicalsettingLogo from "../assets/images/Vector (2).png";
import {
  FaCircle,
  FaFileUpload,
  FaFlag,
  FaHistory,
  FaList,
  FaUpload,
} from "react-icons/fa";
import { BiArchive, BiArrowBack, BiExit, BiUpload } from "react-icons/bi";
import SwitchSubscriber from "./Ats/SwitchSubscriber";

import DocuverusLogo from "../assets/images/docuverus_logo.png";
import {
  Dashboard,
  DashboardCustomizeSharp,
  DashboardOutlined,
  Height,
  Message,
  Square,
} from "@mui/icons-material";
import { useParams } from "react-router";
import { JSONTree } from "react-json-tree";
import React, { useEffect } from "react";
import { ATS_DOCS_URL, SOFTWARE_VERSION, typesofUsers } from "../config";
import { ImUpload2 } from "react-icons/im";
import { RiFileUploadFill } from "react-icons/ri";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import { useLocation } from "react-router-dom";
import MuiList from "@mui/material/List";
import { IoMdSettings } from "react-icons/io";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
// import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { RiListSettingsLine } from "react-icons/ri";
import ArticleIcon from "@mui/icons-material/Article";
import DnsIcon from "@mui/icons-material/Dns";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { Button as MuiButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import GoogleTranslate from "./common/GoogleTranslate";
import { BsShieldFillCheck } from "react-icons/bs";
import { color } from "html2canvas/dist/types/css/types/color";
import AdverseLogo from "../assets/images/Frame (6).png";
import ApplicationLogo from "../assets/images/Frame (12).png";
import { checkModuleAccess, isSuperAdmin } from "../Functions/permissions";
import AdminNotificationLogo from "../assets/images/Frame (7).png";
import AdminResourceLogo from "../assets/images/Frame (11).png";

declare global {
  interface Window {
    google: any;
  }
}

const MyUserMenu = () => {
  const [create, { isSuccess, data }] = useCreate();
  useEffect(() => {
    if (data) {
      localStorage.removeItem("auth");
      window.location.replace(
        window.location.protocol +
          "//" +
          window.location.host +
          window.location.href.split(
            window.location.protocol + "//" + window.location.host
          )[1]
      );
    }
  }, [data]);

  return (
    <UserMenu>
      {/* <Button
            sx={{ width: 140 }}
            startIcon={<BiExit />}
            onClick={() => {
              create("logout");
            }}
            label="Logout"
          /> */}
      <MuiButton
        sx={{ width: 140 }}
        startIcon={<BiExit />}
        onClick={() => {
          create("logout");
        }}
        size="small"
      >
        Logout
      </MuiButton>
    </UserMenu>
  );
};

export const MyAppBar = () => {
  const navigate = useNavigate();
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const PsEnabledSubscriber: any = localStorage.getItem("isPsLogo");
  const isPsLogo = JSON.parse(PsEnabledSubscriber);
  const { data } = useGetIdentity();
  const subscriberView: boolean = data?.subscriberView;
  const Search = styled("div")(({ theme }) => ({
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  }));

  const SearchIconWrapper = styled("div")(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }));

  

  const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "inherit",
    width: "100%",
    "& .MuiInputBase-input": {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)})`,
      transition: theme.transitions.create("width"),
      [theme.breakpoints.up("sm")]: {
        width: "12ch",
        "&:focus": {
          width: "20ch",
        },
      },
    },
  }));

  const imageStyle = {
    width: '150px', 
    height: '50px', 
  };

  const switchSubscriberStatus = localStorage.getItem(
    "RaStore.switchSubscriber"
  )
    ? localStorage.getItem("RaStore.switchSubscriber")
    : "false";
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      navigate("/Ats/getglobalsearch", {
        state: { search: e.target.value },
      });
    }
  };
  return (
    <AppBar
      userMenu={<MyUserMenu />}
      color="primary"
      toolbar={
        <>
          {true && switchSubscriberStatus == "false" && (
            <Search>
              {/* {(permissions?.includes("global_search") && switchSubscriberStatus=='false') && <Search> */}
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                // onChange={(e) => {
                //   let timeout;
                //   if (timeout) clearTimeout(timeout);
                //   timeout = setTimeout(() => {
                //     navigate("/Ats/getglobalsearch", {
                //       state: { search: e.target.value },
                //     });

                //   }, 3500);
                // }}
                onKeyDown={handleKeyDown}
                placeholder="Global Search"
                inputProps={{ "aria-label": "search" }}
              />
            </Search>
          )}
          {true && <SwitchSubscriber />}
          {/* {permissions?.includes("switch_subscriber") && <SwitchSubscriber />} */}
          <LoadingIndicator />
          {/* <GoogleTranslate /> */}
        </>
      }
    >
      {isPsLogo ? (
        <img
          src={PredictScreenLogo}
          alt="Predict Screen Logo"
          style={imageStyle}
        />
      ) : (
        <img src={DocuverusLogo} alt="Docuverus Logo" />
      )}
      &nbsp;
      {!subscriberView && <strong> {switchSubscriberObj?.label}</strong>}
      <Box flex="1" />
      <Box flex="1" />
      {/* <Toolbar>
       
         
        {/* <div className="cus-search">
          <input
            // ref={ref}
            type="text"
            style={{ backgroundColor: '#fff', border: '1px solid #000', borderRadius: '4px' }}
            onChange={(e) => {
              let timeout;
              if (timeout) clearTimeout(timeout);
              timeout = setTimeout(() => {
                navigate("/Ats/Admin/getglobalsearch", {
                  state: { search: e.target.value },
                });
              }, 2000);
            }}
            placeholder="Global Search"
          />
        </div> */}
      {/* {!subscriberView && <SwitchSubscriber />} */}
    </AppBar>
  );
};

export const MyMenu = (props) => {
  const [open, setOpen] = React.useState(false);
  const dataProvider = useDataProvider();
  const [openSettngs, setOpenSettngs] = useStore("openSettings", false);
  const [openMgt, setopenMgt] = useStore("openMgt", false);
  const [userPermission, setUserPermission] = useStore("userPermission", false);
  const [openTerms, setOpenTerms] = useStore("openTermsCondtions", false);
  const [aopenMgt, setaopenMgt] = useStore("aopenMgt", false);
  const [adminUserPermission, setAdminUserPermission] = useStore(
    "adminUserPermission",
    false
  );
  const [openIntegrationSettings, setOpenIntegrationSettings] =
    React.useState(false);
  const { permissions } = usePermissions();
  console.log(permissions, "userpermissions");
  const handleClick = () => {
    setOpen(!open);
  };
  const settingsonClickHandler = () => {
    setOpenSettngs(!openSettngs);
  };
  const mgtonClickHandler = () => {
    setopenMgt(!openMgt);
  };
  const permissiononClickHandler = () => {
    setUserPermission(!userPermission);
  };
  const integrationSettingsonClickHandler = () => {
    setOpenIntegrationSettings(!openIntegrationSettings);
  };
  const location = useLocation();
  const { data } = useGetIdentity();
  const [switchSubscriberObj, setSwitchSubscriberObj] = useStore(
    "switchSubscriberName",
    { value: "", label: "", companyType: "" }
  );
  // const [switchSubscriber, setSwitchSubscriber] = useStore('switchSubscriber');
  // const {permissions:switchSubscriber} = usePermissions();
  const switchSubscriber = localStorage.getItem("RaStore.switchSubscriber");
  const PsEnabledSubscriber:any  = localStorage.getItem("PsEnabledSubscribers");
  const PsEnabledSubscriberJson = JSON.parse(PsEnabledSubscriber);
  console.log("PsEnabledSubscriber",PsEnabledSubscriber)
  const subscriberView: boolean = data?.subscriberView;
  console.log("subscriberView 2",subscriberView)

  const [create] = useCreate();
  const notify = useNotify();
  useEffect(() => {
    console.log(window.location.pathname.split("/")[1]);
    if (window.location.pathname.split("/")[1] == "ml") setOpen(true);
  }, [window.location]);
  //handled redirected back to the old system
  const onClickHandler = () => {
    create(
      "logout",
      {},
      {
        onSuccess: (res) => {
          localStorage?.removeItem("auth");
          localStorage?.removeItem("PsEnabledSubscribers");
          window?.location?.replace(`${ATS_DOCS_URL}`);
        },
        onError: (error) => {
          notify("Error: Retrain process abort", {
            type: "warning",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        },
      }
    );
  };

  // ps code 

  const onClickHandlerPs = async() => {
    try {
       
        const getSubData = localStorage.getItem("PsEnabledSubscribers");
 
       
        if (getSubData !== null) {
            const parsedData = JSON.parse(getSubData);
            console.log("getData", parsedData);
            const { data } = await dataProvider.getList("get-subscriber-enabled-ps", {
              filter: {
                subscriberID: parsedData?.psID,
              },
              pagination: { page: 1, perPage: 10 },
              sort: { field: "id", order: "ASC" },
            });
 
            console.log("datnew",data)
            const sanitizedData = data.map(item => {
              const { id, ...rest } = item; 
              return rest;
          });
          console.log("sanitizedData", sanitizedData[0]);
            const encodedData = encodeURIComponent(JSON.stringify(sanitizedData[0]));
            if(encodedData){
              const url = `https://uat.predictscreen.com/#/Docuverus-PS-Login?newData=${encodedData}`;
               window.open(url, "_blank");
            }
 
        } else {
            console.log("No data found in local storage for 'PsEnabledSubscribers'");
        }
    } catch (error) {
        console.error("Error parsing local storage data:", error);
    }
};


  // ps code

  // get company type Data
  const { data: subscriberThirdPartyMenu } = useGetOne(
    "subscriber-third-party-menu",
    {
      id: switchSubscriberObj?.value,
    },
    {
      refetchOnWindowFocus: false,
      enabled: switchSubscriberObj?.value ? true : false,
    }
  );
  return (
    <Menu {...props}>
      {!(switchSubscriber === "true") &&
      isSuperAdmin(
        checkModuleAccess(permissions, "Dashboard", ""),
        data?.userrole
      ) ? (
        location.pathname.split("/")[1] == "ml" ? (
          <Menu.Item
            to={"/ats"}
            primaryText="Dashboard"
            leftIcon={
              <img
                src={dashboardLogo}
                style={{ color: "#909090", fontSize: "10.7px" }}
                alt="dashboard"
              />
            }
          />
        ) : (
          <Menu.DashboardItem
            leftIcon={
              <img
                src={dashboardLogo}
                style={{ color: "#909090", fontSize: "10.7px" }}
                alt="dashboard"
              />
            }
          />
        )
      ) : null}

      {switchSubscriber === "true" &&
      subscriberView &&
      isSuperAdmin(
        checkModuleAccess(permissions, "Dashboard", ""),
        data?.userrole
      ) ? (
        location.pathname.split("/")[1] == "ml" ? (
          <Menu.Item
            to={"/ats"}
            primaryText="Dashboard"
            leftIcon={
              <img
                src={dashboardLogo}
                style={{ color: "#909090", fontSize: "10.7px" }}
                alt="dashboard"
              />
            }
          />
        ) : (
          <Menu.DashboardItem
            leftIcon={
              <img
                src={dashboardLogo}
                style={{ color: "#909090", fontSize: "10.7px" }}
                alt="dashboard"
              />
            }
          />
        )
      ) : null}

      {switchSubscriber === "true" &&
        !subscriberView &&
        isSuperAdmin(
          checkModuleAccess(permissions, "Dashboard", ""),
          data?.userrole
        ) && (
          <Menu.Item
            to={"/ats/subscriber/inbox/" + switchSubscriberObj?.value}
            primaryText="Dashboard"
            leftIcon={
              <img
                src={dashboardLogo}
                style={{ color: "#909090", fontSize: "10.7px" }}
                alt="dashboard"
              />
            }
          />
        )}

      {(subscriberView || switchSubscriber === "true") &&
        isSuperAdmin(
          checkModuleAccess(permissions, "Settings", ""),
          data?.userrole
        ) &&
        (isSuperAdmin(
          checkModuleAccess(permissions, "Settings", "Archived"),
          data?.userrole
        ) ||
          isSuperAdmin(
            checkModuleAccess(permissions, "Settings", "Archive Docupload"),
            data?.userrole
          ) ||
          isSuperAdmin(
            checkModuleAccess(permissions, "Settings", "Archive Guestcard"),
            data?.userrole
          )) && (
          <Menu.Item
            to={
              "/Ats/subscriber/getarchivedcancelled/" +
              (switchSubscriberObj?.value || data?.subscriberid)
            }
            primaryText="Archived / Cancelled"
            leftIcon={<BiArchive />}
          />
        )}
      {(subscriberView || switchSubscriber === "true") && (
        <Menu.Item
          to={"/Ats/subscriberresource/"}
          primaryText="Help Resources"
          leftIcon={
            <img
              src={AdminResourceLogo}
              style={{ color: "#909090" }}
              alt="Resource"
            />
          }
        />
      )}
      {!subscriberView && !(switchSubscriber === "true") && (
        <Menu.Item
          to="/Ats/SubscriberUsageList"
          primaryText="Subscriber Usage List"
          leftIcon={
            <PeopleIcon style={{ fontSize: "14.7px", color: "#909090" }} />
          }
        />
      )}
      {!subscriberView && !(switchSubscriber === "true") && (
        <Menu.Item
          to="/Ats/getloginactivitylogs"
          primaryText="Log report "
          leftIcon={
            <img
              src={logReport}
              style={{ color: "#909090", fontSize: "10.7px" }}
              alt="log report"
            />
          }
        />
      )}
      {!subscriberView && !(switchSubscriber === "true") && (
        <Menu.Item
          to="/Ats/getocrqueuelist"
          primaryText="SOCR Queue "
          leftIcon={<BookIcon />}
        />
      )}
      {!subscriberView && !(switchSubscriber === "true") && (
        <Menu.Item
          to="/Ats/getEmailSettings"
          primaryText="Email Settings "
          leftIcon={
            <img
              src={EmailSettingsLogo}
              style={{ color: "#909090" }}
              alt="email settings"
            />
          }
        />
      )}
      {/* {(!subscriberView && !(switchSubscriber === "true")) && <Menu.Item to="/ats/termsandconditions" primaryText="Terms and Conditions" leftIcon={<BsShieldFillCheck />} />} */}

      {!(switchSubscriber === "true") &&
        data?.userrole == "Docuverus Super Admin" &&
        checkModuleAccess(permissions, "Terms & Conditions", "") && (
          <MuiList dense disablePadding>
            <Menu.Item
              to="#"
              onClick={(e) => {
                e.preventDefault();
                setOpenTerms(!openTerms);
              }}
              primaryText="Terms and Conditions"
              style={{ fontSize: "13.7px" }}
              leftIcon={openTerms ? <ExpandLess /> : <ExpandMore />}
            />
            {checkModuleAccess(permissions, "Terms & Conditions", "") && (
              <Collapse
                sx={{ background: "#2cc0e414" }}
                in={openTerms}
                timeout="auto"
                unmountOnExit
              >
                <MuiList
                  component="div"
                  disablePadding
                  sx={{ marginLeft: "16px" }}
                >
                  {checkModuleAccess(
                    permissions,
                    "Terms & Conditions",
                    "Applicant"
                  ) && (
                    <Menu.Item
                      to="/ats/termsandconditions_applicant"
                      primaryText="Applicant"
                      leftIcon={
                        <img
                          src={TermnsAndConditions}
                          style={{ fontSize: 11 }}
                        />
                      }
                      style={{ fontSize: "10.7px" }}
                    />
                  )}
                  {checkModuleAccess(
                    permissions,
                    "Terms & Conditions",
                    "Subscriber User"
                  ) && (
                    <Menu.Item
                      to="/ats/termsandconditions_subscriber"
                      primaryText="Subscriber User"
                      leftIcon={
                        <img
                          src={TermnsAndConditions}
                          style={{ fontSize: 11 }}
                        />
                      }
                      style={{ fontSize: "10.7px" }}
                    />
                  )}
                  {checkModuleAccess(
                    permissions,
                    "Terms & Conditions",
                    "Landlord/Third Party"
                  ) && (
                    <Menu.Item
                      to="/ats/termsandconditions_landloard"
                      primaryText="Landloard/Third Party"
                      leftIcon={
                        <img
                          src={TermnsAndConditions}
                          style={{ fontSize: 11 }}
                        />
                      }
                      style={{ fontSize: "10.7px" }}
                    />
                  )}
                  {checkModuleAccess(
                    permissions,
                    "Terms & Conditions",
                    "Data Privacy"
                  ) && (
                    <Menu.Item
                      to="/ats/termsandconditions_privacy"
                      primaryText="Data Privacy"
                      leftIcon={
                        <img
                          src={TermnsAndConditions}
                          style={{ fontSize: 11 }}
                        />
                      }
                      style={{ fontSize: "10.7px" }}
                    />
                  )}
                  {checkModuleAccess(
                    permissions,
                    "Terms & Conditions",
                    "Terms Of Use"
                  ) && (
                    <Menu.Item
                      to="/ats/termsandconditions_termsofuse"
                      primaryText="Terms of Use"
                      leftIcon={
                        <img
                          src={TermnsAndConditions}
                          style={{ fontSize: 11 }}
                        />
                      }
                      style={{ fontSize: "10.7px" }}
                    />
                  )}
                  {/* <Menu.Item to="/ml/predictps" primaryText="Predict Paystub" leftIcon={<RiFileUploadFill size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/predictbs" primaryText="Predict Bank" leftIcon={<FaFileUpload size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/mlclassdetails" primaryText="List & Retrain" leftIcon={<FaList size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/getclassretrainlogs" primaryText="Retrain Logs" leftIcon={<FaHistory size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/getFlaggedDocuments" primaryText="Flagged Documents" leftIcon={<FaFlag size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/getFlaggedSOCRData" primaryText="Flagged SOCR Data" leftIcon={<FaFlag size={11} />} style={{ fontSize: '10.7px' }} /> */}
                </MuiList>
              </Collapse>
            )}
          </MuiList>
        )}

      {!(switchSubscriber === "true") &&
        data?.userrole == "Docuverus Super Admin" &&
        checkModuleAccess(permissions, "Management Report", "") && (
          <MuiList dense disablePadding>
            <Menu.Item
              to="#"
              onClick={(e) => {
                e.preventDefault();
                setaopenMgt(!aopenMgt);
              }}
              primaryText="Management Reports"
              style={{ fontSize: "13.7px" }}
              leftIcon={aopenMgt ? <ExpandLess /> : <ExpandMore />}
            />
            {checkModuleAccess(permissions, "Management Report", "") && (
              <Collapse
                sx={{ background: "#2cc0e414" }}
                in={aopenMgt}
                timeout="auto"
                unmountOnExit
              >
                <MuiList
                  component="div"
                  disablePadding
                  sx={{ marginLeft: "16px" }}
                >
                  {checkModuleAccess(
                    permissions,
                    "Management Report",
                    "Admin Revenue"
                  ) && (
                    <Menu.Item
                      to="/ats/adminrevenue"
                      primaryText="Admin Revenue"
                      leftIcon={<img src={AgentMgt} style={{ fontSize: 11 }} />}
                      style={{ fontSize: "10.7px" }}
                    />
                  )}
                  {checkModuleAccess(
                    permissions,
                    "Management Report",
                    "Screening Revenue "
                  ) && (
                    <Menu.Item
                      to="/ats/adminscreen"
                      primaryText="Screening Revenue"
                      leftIcon={<img src={AgentMgt} style={{ fontSize: 11 }} />}
                      style={{ fontSize: "10.7px" }}
                    />
                  )}
                  {checkModuleAccess(
                    permissions,
                    "Management Report",
                    "Lead Management"
                  ) && (
                    <Menu.Item
                      to="/ats/adminlead"
                      primaryText="Lead Management"
                      leftIcon={<img src={AgentMgt} style={{ fontSize: 11 }} />}
                      style={{ fontSize: "10.7px" }}
                    />
                  )}
                  <Menu.Item
                    to="/ats/adminverification"
                    primaryText="Verification Report"
                    leftIcon={<img src={AgentMgt} style={{ fontSize: 11 }} />}
                    style={{ fontSize: "10.7px" }}
                  />
                  {/* <Menu.Item to="/ats/termsandconditions_termsofuse" primaryText="Terms of Use" leftIcon={<BsShieldFillCheck />} /> */}
                  {/* <Menu.Item to="/ml/predictps" primaryText="Predict Paystub" leftIcon={<RiFileUploadFill size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/predictbs" primaryText="Predict Bank" leftIcon={<FaFileUpload size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/mlclassdetails" primaryText="List & Retrain" leftIcon={<FaList size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/getclassretrainlogs" primaryText="Retrain Logs" leftIcon={<FaHistory size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/getFlaggedDocuments" primaryText="Flagged Documents" leftIcon={<FaFlag size={11} />} style={{ fontSize: '10.7px' }} />
            <Menu.Item to="/ml/getFlaggedSOCRData" primaryText="Flagged SOCR Data" leftIcon={<FaFlag size={11} />} style={{ fontSize: '10.7px' }} /> */}
                </MuiList>
              </Collapse>
            )}
          </MuiList>
        )}

      {!(switchSubscriber === "true") &&
        data?.userrole == "Docuverus Super Admin" && (
          <MuiList dense disablePadding>
            <Menu.Item
              to={"#"}
              onClick={(e) => {
                e.preventDefault();
                setAdminUserPermission(!adminUserPermission);
              }}
              primaryText="Permissions"
              style={{ fontSize: "13.7px" }}
              leftIcon={adminUserPermission ? <ExpandLess /> : <ExpandMore />}
            />
            <Collapse
              sx={{ background: "#2cc0e414" }}
              in={adminUserPermission}
              timeout="auto"
              unmountOnExit
            >
              <MuiList
                component="div"
                disablePadding
                sx={{ marginLeft: "16px" }}
              >
                <Menu.Item
                  to="/ats/AdminPermissions"
                  primaryText="Admin Permissions"
                  leftIcon={<RiFileUploadFill size={11} />}
                  style={{ fontSize: "10.7px" }}
                />
                <Menu.Item
                  to="/ats/thirdParty"
                  primaryText="Landlord/Third Party Permissions"
                  leftIcon={
                    <FaFileUpload size={11} style={{ marginRight: 0 }} />
                  }
                  style={{ fontSize: "10.7px", marginRight: 0 }}
                />
              </MuiList>
            </Collapse>
          </MuiList>
        )}

      {!(switchSubscriber === "true") &&
        data?.userrole == "Docuverus Super Admin" &&
        checkModuleAccess(permissions, "ML Prediction", "") && (
          <MuiList dense disablePadding>
            <Menu.Item
              to={"/ml"}
              onClick={(e) => {
                e.preventDefault();
                handleClick();
              }}
              primaryText="ML Prediction"
              style={{ fontSize: "13.7px" }}
              leftIcon={open ? <ExpandLess /> : <ExpandMore />}
            />
            {/* <ListItemButton onClick={handleClick}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <BsFillGearFill />
                    </ListItemIcon>
                    <ListItemText primary="ML Prediction" primaryTypographyProps={{ style: { fontSize: '13.7px' } }}/>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton> */}
            {checkModuleAccess(permissions, "ML Prediction", "") && (
              <Collapse
                sx={{ background: "#2cc0e414" }}
                in={open}
                timeout="auto"
                unmountOnExit
              >
                <MuiList
                  component="div"
                  disablePadding
                  sx={{ marginLeft: "16px" }}
                >
                  {checkModuleAccess(
                    permissions,
                    "ML Prediction",
                    "Predict Paystub"
                  ) && (
                    <Menu.Item
                      to="/ml/predictps"
                      primaryText="Predict Paystub"
                      leftIcon={<RiFileUploadFill size={11} />}
                      style={{ fontSize: "10.7px" }}
                    />
                  )}
                  {checkModuleAccess(
                    permissions,
                    "ML Prediction",
                    "Predict Statement"
                  ) && (
                    <Menu.Item
                      to="/ml/predictbs"
                      primaryText="Predict Bank"
                      leftIcon={<FaFileUpload size={11} />}
                      style={{ fontSize: "10.7px" }}
                    />
                  )}
                  {checkModuleAccess(
                    permissions,
                    "ML Prediction",
                    "List & Retrain"
                  ) && (
                    <Menu.Item
                      to="/ml/mlclassdetails"
                      primaryText="List & Retrain"
                      leftIcon={<FaList size={11} />}
                      style={{ fontSize: "10.7px" }}
                    />
                  )}
                  {checkModuleAccess(
                    permissions,
                    "ML Prediction",
                    "Retrain Logs"
                  ) && (
                    <Menu.Item
                      to="/ml/getclassretrainlogs"
                      primaryText="Retrain Logs"
                      leftIcon={<FaHistory size={11} />}
                      style={{ fontSize: "10.7px" }}
                    />
                  )}
                  {checkModuleAccess(
                    permissions,
                    "ML Prediction",
                    "Flagged Documents"
                  ) && (
                    <Menu.Item
                      to="/ml/getFlaggedDocuments"
                      primaryText="Flagged Documents"
                      leftIcon={<FaFlag size={11} />}
                      style={{ fontSize: "10.7px" }}
                    />
                  )}
                  {checkModuleAccess(
                    permissions,
                    "ML Prediction",
                    "Flagged SOCR Data"
                  ) && (
                    <Menu.Item
                      to="/ml/getFlaggedSOCRData"
                      primaryText="Flagged SOCR Data"
                      leftIcon={<FaFlag size={11} />}
                      style={{ fontSize: "10.7px" }}
                    />
                  )}
                </MuiList>
              </Collapse>
            )}
          </MuiList>
        )}

      {(subscriberView || switchSubscriber === "true") && (
        // && (data?.userrole !== "Agent" && data?.userrole !== "Manager")
        <MuiList dense disablePadding>
          {isSuperAdmin(
            checkModuleAccess(permissions, "Settings", ""),
            data?.userrole
          ) && (
            <Menu.Item
              to={"/ats/settings"}
              onClick={(e) => {
                e.preventDefault();
                settingsonClickHandler();
              }}
              primaryText="Settings"
              style={{ fontSize: "13.7px" }}
              leftIcon={openSettngs ? <ExpandLess /> : <ExpandMore />}
            />
          )}
          {isSuperAdmin(
            checkModuleAccess(permissions, "Settings", ""),
            data?.userrole
          ) && (
            <Collapse
              sx={{ background: "#2cc0e414" }}
              in={openSettngs}
              timeout="auto"
              unmountOnExit
            >
              <MuiList
                component="div"
                disablePadding
                sx={{ marginLeft: "10px" }}
              >
                {/* {data?.userrole === "Docuverus Super Admin" && */}
                {isSuperAdmin(
                  checkModuleAccess(permissions, "Settings", "Email Settings"),
                  data?.userrole
                ) && (
                  <Menu.Item
                    to={"/ats/settings/emailSettings"}
                    primaryText="Email Settings"
                    leftIcon={<MailOutlineIcon sx={{ fontSize: 11 }} />}
                    style={{ fontSize: "10.7px" }}
                  />
                )}
                {/* } */}
                {/* {data?.userrole === "Docuverus Super Admin" && */}
                {/* {(!subscriberView && !(switchSubscriber === "true")) && <Menu.Item to="/Ats/getEmailSettings" primaryText="Email Settings " leftIcon={ <img src={EmailSettingsLogo} style={{color:"gray"}} alt="email settings" />} />} */}
                {/* {checkModuleAccess(permissions,"Settings","User Setup") && */}

                {data?.userrole === typesofUsers?.docuverusAdmin &&
                checkModuleAccess(
                  permissions,
                  "Other Permissions",
                  "View Users"
                ) ? (
                  <Menu.Item
                    to={"/ats/settings/usersetup"}
                    primaryText="User Setup"
                    leftIcon={
                      <img
                        src={ManageUserLogo}
                        style={{ color: "gray" }}
                        alt="email settings"
                      />
                    }
                    style={{ fontSize: "10.7px" }}
                  />
                ) : data?.userrole != typesofUsers?.docuverusAdmin &&
                  isSuperAdmin(
                    checkModuleAccess(permissions, "Settings", "User Setup"),
                    data?.userrole
                  ) ? (
                  <Menu.Item
                    to={"/ats/settings/usersetup"}
                    primaryText="User Setup"
                    leftIcon={
                      <img
                        src={ManageUserLogo}
                        style={{ color: "gray" }}
                        alt="email settings"
                      />
                    }
                    style={{ fontSize: "10.7px" }}
                  />
                ) : (
                  ""
                )}
                {/* } */}

                {/* for permissions */}
                {(subscriberView || switchSubscriber === "true") && (
                  <MuiList dense disablePadding>
                    {isSuperAdmin(
                      checkModuleAccess(permissions, "Permissions", ""),
                      data?.userrole
                    ) && (
                      <Menu.Item
                        to={"/ats/settings/permission"}
                        onClick={(e) => {
                          e.preventDefault();
                          permissiononClickHandler();
                        }}
                        primaryText="Permissions "
                        style={{ fontSize: "10.7px" }}
                        leftIcon={
                          userPermission ? <ExpandLess /> : <ExpandMore />
                        }
                      />
                    )}
                    {isSuperAdmin(
                      checkModuleAccess(permissions, "Permissions", ""),
                      data?.userrole
                    ) && (
                      <Collapse
                        sx={{ background: "#2cc0e414" }}
                        in={userPermission}
                        timeout="auto"
                        unmountOnExit
                      >
                        <MuiList
                          component="div"
                          disablePadding
                          sx={{ marginLeft: "10px" }}
                        >
                          {/* {data?.userrole === "Docuverus Super Admin" && */}
                          {isSuperAdmin(
                            checkModuleAccess(
                              permissions,
                              "Permissions",
                              "Subscriber Users"
                            ),
                            data?.userrole
                          ) && (
                            <Menu.Item
                              to={"/ats/settings/permission/userPermission"}
                              primaryText="User Permissions"
                              leftIcon={
                                <img
                                  src={ManageUserLogo}
                                  style={{ color: "gray" }}
                                  alt="User Permission"
                                />
                              }
                              style={{ fontSize: "10.7px" }}
                            />
                          )}
                          {isSuperAdmin(
                            checkModuleAccess(
                              permissions,
                              "Permissions",
                              "Third Party Users"
                            ),
                            data?.userrole
                          ) && (
                            <Menu.Item
                              to={
                                "/ats/settings/permission/ThirdPartyPermission"
                              }
                              primaryText=" Landlord/Third Party Permissions"
                              leftIcon={
                                <img
                                  src={ManageUserLogo}
                                  style={{ color: "gray" }}
                                  alt="Third Party Permission"
                                />
                              }
                              style={{ fontSize: "10.7px" }}
                            />
                          )}
                        </MuiList>
                      </Collapse>
                    )}
                  </MuiList>
                )}
                {/* } */}
                {/* {data?.userrole === "Docuverus Super Admin" && */}
                {isSuperAdmin(
                  checkModuleAccess(permissions, "Settings", "Hierarchy Level"),
                  data?.userrole
                ) && (
                  <Menu.Item
                    to={"/ats/settings/hierarchyLevel"}
                    primaryText="Hierarchy Level"
                    leftIcon={<AccountTreeIcon sx={{ fontSize: 11 }} />}
                    style={{ fontSize: "10.7px" }}
                  />
                )}
                {
                  <Menu.Item
                    to={"/ats/settings/predefinedMessage"}
                    primaryText="Predefined Messages"
                    leftIcon={<Message sx={{ fontSize: 11 }} />}
                    style={{ fontSize: "10.7px" }}
                  />
                }

                {/* } */}

                {
                  //  data?.userrole === "Docuverus Super Admin" &&
                  isSuperAdmin(
                    checkModuleAccess(
                      permissions,
                      "Settings",
                      "Adverse Letters"
                    ),
                    data?.userrole
                  ) && (
                    <Menu.Item
                      to={"/ats/settings/getadverseletter"}
                      primaryText="Adverse Letter"
                      leftIcon={
                        <img
                          src={AdverseLogo}
                          style={{ color: "gray" }}
                          alt="email settings"
                        />
                      }
                      style={{ fontSize: "10.7px" }}
                    />
                  )
                }
                {isSuperAdmin(
                  checkModuleAccess(
                    permissions,
                    "Settings",
                    "Application Profile"
                  ),
                  data?.userrole
                ) && (
                  <Menu.Item
                    to={"/ats/settings/applicationProfile"}
                    primaryText="Application Profile"
                    leftIcon={
                      <img
                        src={ApplicationLogo}
                        style={{ color: "gray" }}
                        alt="Application Profile"
                      />
                    }
                    style={{ fontSize: "10.7px" }}
                  />
                )}
                {
                  //  data?.userrole === "Docuverus Super Admin" &&

                  (switchSubscriberObj?.companyType === "Real Estate" ||
                    data?.subscribertype === "Real Estate") &&
                    isSuperAdmin(
                      checkModuleAccess(
                        permissions,
                        "Settings",
                        "Confidentiality Log"
                      ),
                      data?.userrole
                    ) && (
                      <Menu.Item
                        to={"/ats/settings/getconfidentialitylogs"}
                        primaryText="Confidentiality Log"
                        leftIcon={
                          <img
                            src={Loglogo}
                            style={{ color: "gray" }}
                            alt="email settings"
                          />
                        }
                        style={{ fontSize: "10.7px" }}
                      />
                    )
                }
                {/* {data?.userrole === "Docuverus Super Admin" && */}
                {isSuperAdmin(
                  checkModuleAccess(permissions, "Settings", "Invitation Logs"),
                  data?.userrole
                ) && (
                  <Menu.Item
                    to={"/ats/settings/getinvitationlogs"}
                    primaryText="Invitation Log"
                    leftIcon={
                      <img
                        src={Loglogo}
                        style={{ color: "gray" }}
                        alt="email settings"
                      />
                    }
                    style={{ fontSize: "10.7px" }}
                  />
                )}
                {/* } */}
                {/* <Menu.Item style={{ fontSize: "10.7px" }} to="/ats/settings/termsandconditions" primaryText="Terms and Conditions" leftIcon={<BsShieldFillCheck />} /> */}
                {/* {switchSubscriberObj?.companyType === "Property Management" && ( */}
                {isSuperAdmin(
                  checkModuleAccess(
                    permissions,
                    "Settings",
                    "Terms & Conditions"
                  ),
                  data?.userrole
                ) && (
                  <Menu.Item
                    style={{ fontSize: "10.7px" }}
                    to="/ats/settings/termsandconditions"
                    primaryText="Terms and Conditions"
                    leftIcon={<BsShieldFillCheck />}
                  />
                )}

                {(switchSubscriberObj?.companyType === "Property Management" ||
                  data?.subscribertype === "Property Management") &&
                data?.userrole === "Docuverus Admin" &&
                isSuperAdmin(
                  checkModuleAccess(
                    permissions,
                    "Other Permissions",
                    "View Properties/Offices"
                  ),
                  data?.userrole
                ) ? (
                  <Menu.Item
                    to={"/ats/settings/propertySetup"}
                    primaryText="Property Setup"
                    leftIcon={<MapsHomeWorkIcon sx={{ fontSize: 11 }} />}
                    style={{ fontSize: "10.7px" }}
                  />
                ) : (switchSubscriberObj?.companyType ===
                    "Property Management" ||
                    data?.subscribertype === "Property Management") &&
                  data?.userrole != "Docuverus Admin" &&
                  isSuperAdmin(
                    checkModuleAccess(
                      permissions,
                      "Settings",
                      "Property Setup"
                    ),
                    data?.userrole
                  ) ? (
                  <Menu.Item
                    to={"/ats/settings/propertySetup"}
                    primaryText="Property Setup"
                    leftIcon={<MapsHomeWorkIcon sx={{ fontSize: 11 }} />}
                    style={{ fontSize: "10.7px" }}
                  />
                ) : (
                  ""
                )}
                {(switchSubscriberObj?.companyType === "Real Estate" ||
                  data?.subscribertype === "Real Estate") &&
                data?.userrole === "Docuverus Admin" &&
                isSuperAdmin(
                  checkModuleAccess(
                    permissions,
                    "Other Permissions",
                    "View Properties/Offices"
                  ),
                  data?.userrole
                ) ? (
                  <Menu.Item
                    to={"/ats/settings/officeSetup"}
                    primaryText="Office Setup"
                    leftIcon={<MapsHomeWorkIcon sx={{ fontSize: 11 }} />}
                    style={{ fontSize: "10.7px" }}
                  />
                ) : (switchSubscriberObj?.companyType === "Real Estate" ||
                    data?.subscribertype === "Real Estate") &&
                  data?.userrole != "Docuverus Admin" &&
                  isSuperAdmin(
                    checkModuleAccess(permissions, "Settings", "Office Setup"),
                    data?.userrole
                  ) ? (
                  <Menu.Item
                    to={"/ats/settings/officeSetup"}
                    primaryText="Office Setup"
                    leftIcon={<MapsHomeWorkIcon sx={{ fontSize: 11 }} />}
                    style={{ fontSize: "10.7px" }}
                  />
                ) : (
                  ""
                )}
                {/* {switchSubscriberObj?.companyType === "Real Estate" && (
              <Menu.Item
                to={"/settings/getleadmanagementreport"}
                primaryText="Lead Management"
                leftIcon={<MapsHomeWorkIcon sx={{ fontSize: 11 }} />}
                style={{ fontSize: "10.7px" }}
              />
            )} */}
                {/* {subscriberThirdPartyMenu?.data &&
                  subscriberThirdPartyMenu?.data?.length > 0 && (
                    <>
                      <Menu.Item
                        to={"/ats/settings/integrationSettings"}
                        onClick={(e) => {
                          e.preventDefault();
                          integrationSettingsonClickHandler();
                        }}
                        primaryText="Integration Settings"
                        style={{ fontSize: "13.7px" }}
                        leftIcon={
                          openIntegrationSettings ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          )
                        }
                      />
                      <Collapse
                        sx={{ background: "#2cc0e414" }}
                        in={openIntegrationSettings}
                        timeout="auto"
                        unmountOnExit
                      >
                        <MuiList
                          component="div"
                          disablePadding
                          sx={{ marginLeft: "10px" }}
                        >
                          {subscriberThirdPartyMenu?.data &&
                            subscriberThirdPartyMenu?.data?.length > 0 &&
                            subscriberThirdPartyMenu?.data?.map((menus) => (
                              <Menu.Item
                                to={`/ats/settings/integrationSettings/${menus?.appName}`}
                                primaryText={menus?.appName}
                                leftIcon={<DnsIcon sx={{ fontSize: 11 }} />}
                                style={{ fontSize: "10.7px" }}
                                key={menus?.appID}
                              />
                            ))}
                        </MuiList>
                      </Collapse>
                    </>
                  )} */}
                  {  (
                <>
                  <Menu.Item to={'/ats/integrationSettings'} onClick={(e) => { e.preventDefault(); integrationSettingsonClickHandler() }} primaryText="Integration Settings" style={{ fontSize: '13.7px' }} leftIcon={openIntegrationSettings ? <ExpandLess /> : <ExpandMore />} />
                  <Collapse
                    sx={{ background: "#2cc0e414" }}
                    in={openIntegrationSettings}
                    timeout="auto"
                    unmountOnExit
                  >
                    <MuiList component="div" disablePadding sx={{ marginLeft: "10px" }}>
                      
                          <Menu.Item
                            to={"/ats/integrationSettings/screeningReports"}
                            primaryText="Screening Reports"
                            leftIcon={<DnsIcon sx={{ fontSize: 11 }} />}
                            style={{ fontSize: "10.7px" }}
                          />
                      
                    </MuiList>
                  </Collapse>
                </>
              )}
              </MuiList>
            </Collapse>
          )}
        </MuiList>
      )}

      {(subscriberView || switchSubscriber === "true") && (
        //  && (data?.userrole !== "Agent" && data?.userrole !== "Manager")
        <MuiList dense disablePadding>
          {isSuperAdmin(
            checkModuleAccess(permissions, "Management Report", ""),
            data?.userrole
          ) && (
            <Menu.Item
              to={"/ats/managementreport"}
              onClick={(e) => {
                e.preventDefault();
                mgtonClickHandler();
              }}
              primaryText="Management Reports"
              style={{ fontSize: "13.7px" }}
              leftIcon={openMgt ? <ExpandLess /> : <ExpandMore />}
            />
          )}
          {isSuperAdmin(
            checkModuleAccess(permissions, "Management Report", ""),
            data?.userrole
          ) && (
            <Collapse
              sx={{ background: "#2cc0e414" }}
              in={openMgt}
              timeout="auto"
              unmountOnExit
            >
              <MuiList
                component="div"
                disablePadding
                sx={{ marginLeft: "10px" }}
              >
                {/* {data?.userrole === "Docuverus Super Admin" && */}
                {isSuperAdmin(
                  checkModuleAccess(
                    permissions,
                    "Management Report",
                    "Lead Management"
                  ),
                  data?.userrole
                ) && (
                  <Menu.Item
                    to={"/ats/managementreport/getleadmanagementreport"}
                    primaryText="Lead Management"
                    leftIcon={<img src={Leadmgt} style={{ fontSize: 11 }} />}
                    style={{ fontSize: "10.7px" }}
                  />
                )}
                {/* } */}

                {isSuperAdmin(
                  checkModuleAccess(
                    permissions,
                    "Management Report",
                    "Revenue Mgmt"
                  ),
                  data?.userrole
                ) && (
                  <Menu.Item
                    to={"/ats/managementreport/getrevenuemanagementreport"}
                    primaryText="Revenue Management"
                    leftIcon={<img src={Revenuemgt} style={{ fontSize: 11 }} />}
                    style={{ fontSize: "10.7px" }}
                  />
                )}

                {isSuperAdmin(
                  checkModuleAccess(
                    permissions,
                    "Management Report",
                    "Doc Upload Report"
                  ),
                  data?.userrole
                ) && (
                  <Menu.Item
                    to={"/ats/managementreport/getdocuploadmanagementreport"}
                    primaryText="Docupload Management"
                    leftIcon={
                      <img src={Docuploadmgt} style={{ fontSize: 11 }} />
                    }
                    style={{ fontSize: "10.7px" }}
                  />
                )}
              </MuiList>
            </Collapse>
          )}
        </MuiList>
      )}

      {/* <Menu.Item to={"/ats/emailSettings"} primaryText="Settings" leftIcon={<IoMdSettings />} /> */}
      {/* <Menu.Item to={"/ats/emailSettings"} primaryText="Email Settings" leftIcon={<MailOutlineIcon />} /> */}
      {!subscriberView && !(switchSubscriber === "true") && (
        <Menu.Item
          to={"/ats/geographicalsetting"}
          primaryText="Geographic Rule Setting"
          leftIcon={
            <img
              src={GeographicalsettingLogo}
              style={{ color: "gray" }}
              alt="Manage Users"
            />
          }
        />
      )}
      {!subscriberView && !(switchSubscriber === "true") && (
        <Menu.Item
          to={"/ats/manageSubscribers"}
          primaryText="Manage Subscribers"
          leftIcon={<RiListSettingsLine />}
        />
      )}

      {!subscriberView &&
        !(switchSubscriber === "true") &&
        (data?.userrole === typesofUsers?.docuverusSuperAdmin ||
          checkModuleAccess(
            permissions,
            "Other Permissions",
            "View Users"
          )) && (
          <Menu.Item
            to={"/ats/manageUsers"}
            primaryText="Manage Users"
            leftIcon={
              <img
                src={ManageUserLogo}
                style={{ color: "gray" }}
                alt="Manage Users"
              />
            }
          />
        )}
      {!subscriberView && !(switchSubscriber === "true") && (
        <Menu.Item
          to={"/ats/adminresource"}
          primaryText="Manage Help Resources"
          leftIcon={
            <img
              src={AdminResourceLogo}
              style={{ color: "gray" }}
              alt="Manage Users"
            />
          }
        />
      )}
      {!subscriberView && !(switchSubscriber === "true") && (
        <Menu.Item
          to={"/ats/adminnotification"}
          primaryText="System Notifications"
          leftIcon={
            <img
              src={AdminNotificationLogo}
              style={{ color: "gray" }}
              alt="Manage Users"
            />
          }
        />
      )}
      <Menu.Item
        to={"#"}
        onClick={() => onClickHandler()}
        primaryText="Back to Old system"
        leftIcon={<BiArrowBack />}
      />
      {(switchSubscriber == "true" && PsEnabledSubscriberJson?.isEnabled == true) && <Menu.Item to={"#"} onClick={() => onClickHandlerPs()} primaryText="Go to home" leftIcon={<BiArrowBack />} /> }
    </Menu>
    
  );
};

const MySidebar = (props) => (
  <Sidebar
    sx={{
      "& .RaSidebar-fixed": {
        backgroundColor: "#eeeeee",
        //  width:"40%",
      },
    }}
    {...props}
  />
);

const MyLayout = (props: any) => (
  <div>
    <Layout
      // sx={{'& .RaLayout-appFrame':{margin:0}}}
      sx={{ "& .RaLayout-appFrame": { marginTop: "50px" } }}
      {...props}
      appBar={MyAppBar}
      menu={MyMenu}
      sidebar={MySidebar}
      //   notification=""
    >
      <div style={{ minHeight: "85vh" }}> {props?.children}</div>

      <footer
        style={{
          backgroundColor: "#f8f9fa",
          padding: "10px",
          //position: "relative",
          bottom: "0",
          // width: "100%",
          //zIndex: "100", // You can adjust the z-index if needed
          display: "flex",
          justifyContent: "space-between", // Align content to the left and right edges
          alignItems: "center", // Vertically center content
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <p style={{ fontSize: "12px", margin: 0 }}>
            Need help completing your application? Docuverus can be reached by
            phone at <b>856.886.8843</b> or by email at{" "}
            <b>support@docuverus.com</b>
          </p>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <p
            style={{
              fontSize: "12px",
              margin: 0,
              marginRight: "20px",
              textAlign: "right",
            }}
          >
            © 2024 Docuverus (version{SOFTWARE_VERSION} )
          </p>
        </div>
      </footer>
    </Layout>
  </div>
);

export default MyLayout;
